import { carmaCsrClient } from 'src/services/instance/carmaCsrClient';

type NewsletterLeadApiPayload = {
  email: string;
  recaptcha_token?: string;
};

/**
 * Create a newsletter lead
 */
export const createNewsletterLead = (email: string, recaptchaToken?: string) =>
  carmaCsrClient
    .post<NewsletterLeadApiPayload>(`enquiry/newsletter`, {
      json: {
        email,
        recaptcha_token: recaptchaToken,
      },
    })
    .json();
