'use client';

import { FC } from 'react';

import { productDetailPayloadToBaseCarData } from 'src/data/ProductApi/ProductDetailPayloadToBaseCarData';
import { ProductCard } from 'src/plp/components/ProductCard/ProductCard';
import { ProductListDataRow } from 'src/types/CataloguePage.types';
import { pushToDataLayerProductCard } from 'src/utils/pushToDataLayer';

type Props = {
  product: ProductListDataRow;
  index: number;
  carouselName?: string;
  carouselValue?: string;
};

export const VehicleCarouselTile: FC<Props> = ({ product, index, carouselName, carouselValue }) => (
  <ProductCard
    carData={productDetailPayloadToBaseCarData(product)}
    weeklyRepayment={product.weeklyRepayment}
    defaultRate={product.defaultRate}
    variant="default"
    onCardClick={
      carouselName && carouselValue
        ? () => {
            pushToDataLayerProductCard(product, index, {
              event: 'carousel_tile_click',
              name: carouselName,
              value: carouselValue,
            });
          }
        : undefined
    }
  />
);
