'use client';

import dynamic from 'next/dynamic';

const Tooltip = dynamic(() => import('./Tooltip').then((mod) => mod.Tooltip), { ssr: false });
const TooltipTrigger = dynamic(() => import('./Tooltip').then((mod) => mod.TooltipTrigger), { ssr: false });
const TooltipContent = dynamic(() => import('./Tooltip').then((mod) => mod.TooltipContent), { ssr: false });

export const TooltipDynamic = Tooltip;
export const TooltipTriggerDynamic = TooltipTrigger;
export const TooltipContentDynamic = TooltipContent;
