'use client';

import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { getSha256Hash } from 'src/general/helpers/getSha256Hash';
import { initVisibilityObserver, ObserverValue } from 'src/general/helpers/initVisibilityObserver';

type Props = {
  image: string;
  video: string;
  videoMobile?: string;
  className?: string;
};

/**
 * Component loading a video only when in viewport
 */
export const VideoAsync: FC<Props> = ({ image, video, videoMobile, className }) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoId, setVideoId] = useState<string | null>(null);

  const handleVisibility = useCallback((value: ObserverValue) => {
    if (videoRef.current && value === 'visible') {
      const video = videoRef.current;
      video.load();
      video.play();
      // Disconnect the observer
      observerRef.current?.disconnect();
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    getSha256Hash(video).then((value) => {
      if (mounted) {
        setVideoId(value);
      }
    });
    return () => {
      mounted = false;
    };
  }, [video]);

  useEffect(() => {
    if (videoId) {
      observerRef.current = initVisibilityObserver(videoId, handleVisibility);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [videoId, handleVisibility]);

  return (
    <video
      poster={image}
      preload="none"
      loop
      muted
      playsInline
      className={className}
      ref={videoRef}
      id={videoId ?? undefined}
    >
      {!!videoMobile && <source src={videoMobile} media="(max-width: 720px)" />}
      <source src={video} />
    </video>
  );
};
