'use client';

import dynamic from 'next/dynamic';
import { TradeInSearchCarLoading } from './TradeInSearchCarLoading';

const TradeInSearchCar = dynamic(() => import('./TradeInSearchCar').then((mod) => mod.TradeInSearchCar), {
  ssr: false,
  loading: () => <TradeInSearchCarLoading />,
});

export const TradeInSearchCarDynamic = TradeInSearchCar;
