import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

import styles from './Divider.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
}

export const Divider: FC<Props> = ({ children, className, contentClassName }) => (
  <div className={clsx(styles.root, className)} role="separator">
    <div className={clsx(styles.content, contentClassName)}>{children}</div>
  </div>
);
