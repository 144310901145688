'use client';

import { isEmpty } from 'lodash';
import { ComponentProps, FC, useEffect, useState } from 'react';
import { Fetcher } from 'swr';
import useSWRImmutable from 'swr/immutable';
import Cookies from 'universal-cookie';
import { shallow } from 'zustand/shallow';

import { COOKIE_RECENTLY_VIEWED } from 'src/constants';
import { fetchVehiclesBySku } from 'src/data/ProductApi/ProductApiListVehicles';
import { productDetailPayloadToBaseCarData } from 'src/data/ProductApi/ProductDetailPayloadToBaseCarData';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { sortBySku } from 'src/general/helpers/sortBySku';
import { PlpMarketingTile } from 'src/plp/components/PlpMarketingTile/PlpMarketingTile';
import { ProductCard } from 'src/plp/components/ProductCard/ProductCard';
import { ProductCardLoading } from 'src/plp/components/ProductCard/ProductCardLoading';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';
import { trackFeatureEngagement } from 'src/utils/pushToDataLayer';

import { clsx } from 'clsx';
import { recentFilterSearchStore } from 'src/stores/recentFilterSearchStore';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import { RecentSearches } from '../RecentSearches/RecentSearches';
import { RecentlyViewedCarouselSection } from './CarouselSection';
import styles from './RecentlyViewedCarousel.module.scss';

const MIN_RECENTLY_VIEWED = 1;

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    marketingItems?: ComponentProps<typeof PlpMarketingTile>[];
  };
}

const skusFetcher: Fetcher<ProductListDataPayload, { id: string; skus: string[] }> = ({ skus }) => {
  if (skus.length) {
    return fetchVehiclesBySku(skus);
  }
  return Promise.resolve({
    results: [],
    total: 0,
  });
};

export const RecentlyViewedCarousel: FC<Props> = ({ sectionData, sectionBackground }) => {
  const [recentlyViewed, setRecentlyViewed] = useState<string[]>([]);
  /**
   * Display condition for recently viewed carousel: at least 2 cars without a marketing tile or at least 1 car with a marketing tile
   */
  const { data, isLoading } = useSWRImmutable({ id: 'fetchVehiclesBySku', skus: recentlyViewed }, skusFetcher);
  const { recentFilterSearches } = recentFilterSearchStore(
    (state) => ({
      recentFilterSearches: state.recentFilterSearches,
    }),
    shallow,
  );

  useEffect(() => {
    const cookies = new Cookies();
    const recentlyViewedData = cookies.get(COOKIE_RECENTLY_VIEWED);
    if (recentlyViewedData && Array.isArray(recentlyViewedData)) {
      setRecentlyViewed(recentlyViewedData);
    }
  }, []);

  if (recentlyViewed.length < MIN_RECENTLY_VIEWED && recentFilterSearches.length < MIN_RECENTLY_VIEWED) {
    return null;
  }

  const tiles = generateTiles(isLoading, recentlyViewed, data?.results, sectionData.marketingItems);

  return (
    <section className={clsx(getSectionClasses(sectionBackground), { [styles.root]: recentFilterSearches.length > 0 })}>
      {recentFilterSearches.length > 0 && (
        <RecentSearches recentSearches={recentFilterSearches} className={styles.recentSearches} />
      )}
      <div className={styles.recentlyViewedCarouselWrapper}>
        {!isEmpty(sectionData.title) && (
          <header className={styles.header}>
            <h2 className="c-fs-h4">{sectionData.title}</h2>
          </header>
        )}
        <RecentlyViewedCarouselSection tiles={tiles} />
      </div>
    </section>
  );
};

const generateTiles = (
  isLoading: boolean,
  skus: string[],
  results?: ProductListDataPayload['results'],
  marketingItems?: ComponentProps<typeof PlpMarketingTile>[],
): ComponentProps<typeof CarouselSection>['tiles'] => {
  if (isLoading || !results) {
    return [...Array(4).keys()].map((item) => ({
      id: `${item}`,
      tile: <ProductCardLoading key={`loading-${item}`} variant="default" />,
    }));
  }

  // Sort the results based on the skus
  const sortedResults = sortBySku(results, skus);

  const tiles = sortedResults.map((product, index) => ({
    id: product.sku,
    tile: (
      <ProductCard
        carData={productDetailPayloadToBaseCarData(product)}
        weeklyRepayment={product.weeklyRepayment}
        defaultRate={product.defaultRate}
        variant="default"
        onCardClick={() => {
          trackFeatureEngagement({
            event: 'feature_engagement_click',
            payload: {
              type: 'click',
              feature: 'recentlyViewed',
              component: 'carousel',
              index,
            },
          });
        }}
      />
    ),
  }));

  if (marketingItems) {
    const marketingTiles = marketingItems.map((tile) => ({
      id: tile.title ?? '',
      tile: <PlpMarketingTile {...tile} variant="carousel" />,
    }));
    tiles.push(...marketingTiles);
  }

  return tiles;
};
