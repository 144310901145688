import { isEmpty } from 'lodash';
import { FC, ReactNode } from 'react';

import { IconCaretRight } from 'src/general/Icons/IconCaretRight';
import { Button } from 'src/general/components/Button/Button';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { ConditionalWrapper } from 'src/general/components/ConditionalWrapper/ConditionalWrapper';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import classes from './CarouselTabCars.module.scss';

interface CarouselTabCarsProps {
  /** Name used to track GTM events */
  carouselName?: string;
  /** Name used to track GTM events */
  carouselValue?: string;
  tiles: {
    id: string;
    tile: ReactNode;
  }[];
  footerBanner?: string;
  cta?: {
    label: ReactNode;
    url: string;
  };
}

export const CarouselTabCars: FC<CarouselTabCarsProps> = ({
  tiles,
  footerBanner,
  cta,
  carouselName,
  carouselValue,
}) => {
  const hasFooterBanner = !isEmpty(footerBanner);

  return (
    <>
      <CarouselSection tiles={tiles} key={`${carouselValue}-${tiles.length}`} carouselName={carouselName} />
      {cta && (
        <footer className={classes.footer}>
          <ConditionalWrapper
            condition={hasFooterBanner}
            wrapper={(children) => (
              <div className={classes.footerBanner}>
                <div className={classes.footerBannerContent}>{footerBanner}</div>
                {children}
              </div>
            )}
          >
            <Button
              href={cta.url}
              variant={hasFooterBanner ? 'secondary' : 'primary'}
              size="medium"
              endIcon={<IconCaretRight />}
              onClick={() => {
                if (!isEmpty(carouselName) && !isEmpty(carouselValue)) {
                  pushToDataLayer({ event: 'carousel_footer_cta_click', name: carouselName, value: carouselValue });
                }
              }}
            >
              {cta.label}
            </Button>
          </ConditionalWrapper>
        </footer>
      )}
    </>
  );
};
