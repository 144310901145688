export type ObserverValue = 'above' | 'below' | 'visible';

/**
 * This function initialise an intersection observer to detect if an element is above, below or within the viewport
 */
export const initVisibilityObserver = (
  /** The observed element id */
  targetId: string,
  /** The callback function called when the observed element change */
  observerCallback: (value: ObserverValue) => void,
  /**
   * Top margin adjustment for the observer.
   * Must be in `px`
   * The negative value is already handled in the rootMargin declaration
   */
  topMargin: number = 0,
  /**
   * Bottom margin adjustment for the observer.
   * Must be in `px`
   * The negative value is already handled in the rootMargin declaration
   */
  bottomMargin: number = 0,
) => {
  if (typeof IntersectionObserver === 'undefined') {
    return null;
  }

  const target = document.getElementById(targetId);
  const observer = new IntersectionObserver(
    ([entry]) => {
      let observerValue: ObserverValue = 'below';
      if (entry.isIntersecting) {
        observerValue = 'visible';
      } else if (entry.intersectionRect.top === entry.rootBounds?.top) {
        observerValue = 'above';
      }
      observerCallback(observerValue);
    },
    {
      root: null,
      threshold: 0,
      rootMargin: `${topMargin * -1}px 0px ${bottomMargin * -1}px 0px`,
    },
  );

  if (target) {
    observer.observe(target);
  } else {
    // Adding log in case the element is not available
    // eslint-disable-next-line no-console
    console.warn(`Element with id: "${targetId}" not present in the DOM. Observer not initialised.`);
  }

  return observer;
};
