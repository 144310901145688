'use client';

import dynamic from 'next/dynamic';

const FinanceHeroBannerForm = dynamic(
  () => import('./FinanceHeroBannerForm').then((mod) => mod.FinanceHeroBannerForm),
  { ssr: false },
);

export const FinanceHeroBannerFormDynamic = FinanceHeroBannerForm;
