import { clsx } from 'clsx';
import { FC } from 'react';

import styles from './Skeleton.module.scss';

interface Props {
  type?: 'text';
  className?: string;
  /** Width of the element in percent or with a specific CSS unit */
  size?: number | string;
}

export const Skeleton: FC<Props> = ({ type = 'text', className, size = 100 }) => (
  <span
    className={clsx(styles.root, styles[type], className)}
    aria-hidden="true"
    style={{ width: typeof size === 'number' ? `${size}%` : size }}
  />
);
