import { FC } from 'react';

import { clsx } from 'clsx';
import { Link } from 'src/general/components/Link/Link';
import { Shade } from 'src/general/components/Shade/Shade';
import { IconArrowRight } from 'src/general/Icons/IconArrowRight';
import { getFilter } from './RecentSearch.helper';
import styles from './RecentSearch.module.scss';
import { RecentFilterSearch } from './RecentSearch.type';

type RecentSearchProps = RecentFilterSearch & {
  className?: string;
  onClick: () => void;
};

export const RecentSearch: FC<RecentSearchProps> = (props) => {
  const search = getFilter({ ...props });

  return (
    <Link
      href={props.url}
      className={clsx(styles.root, 'shade_container', 'shade_container--grey', props.className)}
      onClick={props.onClick}
      data-testid={`recent-search-${props.id}`}
    >
      <Shade />
      <div className={styles.searchContent}>
        <div className={clsx(styles.searchTitle, 'c-fs-body1', 'c-fw-bold')}>{search.title}</div>
        {search.caption && (
          <div className={clsx(styles.searchCaption, 'c-fs-caption2', 'c-fw-normal')}>{search.caption}</div>
        )}
      </div>
      <div className={styles.searchIconsContainer}>
        <IconArrowRight className={styles.icon} />
      </div>
    </Link>
  );
};
