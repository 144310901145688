'use client';

import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';

interface Props extends FlexibleSection {
  sectionData: {
    formId: string;
  };
}

const IframeResizer = dynamic(() => import('@open-iframe-resizer/react').then((mod) => mod.IframeResizer), {
  ssr: false,
});

export const ActiveCampaignFormSection: FC<Props> = ({ sectionData, sectionBackground }: Props) => {
  const { formId } = sectionData;

  return (
    <section className={clsx(getSectionClasses(sectionBackground))}>
      <IframeResizer src={`/active-campaign/${formId}`} style={{ border: 'none', width: '100%' }} />
    </section>
  );
};
