'use client';

import dynamic from 'next/dynamic';
import { ComponentProps, FC } from 'react';

import { handleCarmaClientErrorMessage } from 'src/data/Carma/helpers/handleCarmaClientErrorMessage';
import { createNewsletterLead } from 'src/data/LeadApi/LeadApi';

const NewsletterSignup = dynamic(() => import('./NewsletterSignup').then((mod) => mod.NewsletterSignup), {
  ssr: false,
});

const onSubmit = async (email: string, recaptchaToken?: string): Promise<true | string> => {
  try {
    await createNewsletterLead(email, recaptchaToken);
  } catch (error) {
    const errorMessage = handleCarmaClientErrorMessage(error);
    return errorMessage;
  }
  return true;
};

export const NewsletterSignupWrapper: FC<Omit<ComponentProps<typeof NewsletterSignup>, 'onSubmit'>> = (props) => (
  <NewsletterSignup {...props} onSubmit={onSubmit} />
);
