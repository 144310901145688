'use client';

import { clsx } from 'clsx';
import { isEmpty, isNil } from 'lodash';
import { FC, ReactNode } from 'react';
import { Image } from 'src/general/components/Image/Image';
import { Tabs } from 'src/general/components/Tabs/Tabs';
import { Tab } from 'src/general/components/Tabs/Tabs.type';
import { slugify } from 'src/general/helpers/slugify';
import { CarouselTabCars } from 'src/hygiene/components/CarouselTabCars/CarouselTabCars';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './VehicleCarousel.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    carouselName: string;
    title?: string;
    tabs: {
      title: string;
      icon?: string;
      tiles: {
        id: string;
        tile: ReactNode;
      }[];
      footerBanner?: string;
      cta?: {
        buttonType: string;
        label: string;
        href: string;
      };
    }[];
  };
}

export const VehicleCarousel: FC<Props> = ({ sectionData, sectionBackground }) => {
  const tabs: Tab[] = sectionData.tabs.map((item, index) => {
    const id = `${slugify(item.title)}-${index}`;

    return {
      id,
      title: item.title,
      icon: !isEmpty(item.icon) ? <Image src={item.icon!} alt="" width={48} height={24} /> : undefined,
      content: (
        <CarouselTabCars
          carouselName={sectionData.carouselName}
          carouselValue={id}
          tiles={item.tiles}
          footerBanner={item.footerBanner}
          cta={
            item.cta?.buttonType === 'link'
              ? {
                  label: item.cta.label,
                  url: item.cta.href,
                }
              : undefined
          }
        />
      ),
      onClick: () => {
        pushToDataLayer({
          event: 'carousel_tab_change',
          carousel: sectionData.carouselName,
          tab: id,
        });
      },
    } as Tab;
  });

  return (
    <section className={clsx(styles.root, getSectionClasses(sectionBackground))}>
      {(!isNil(sectionData.title) || !isEmpty(sectionData.title)) && (
        <header className={styles.header}>
          <h2 className={clsx('c-fw-bold', 'c-fs-h4')}>{sectionData.title}</h2>
        </header>
      )}
      {tabs.length === 1 ? tabs[0].content : <Tabs tabsId={sectionData.carouselName} tabs={tabs} />}
    </section>
  );
};
