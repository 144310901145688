'use client';

import { clsx } from 'clsx';

import { AnimatePresence, m } from 'framer-motion';
import { FC, useState } from 'react';
import { LazyMotionWrapperFull } from '../LazyMotionWrapper/LazyMotionWrapperFull';
import { ScrollBox } from '../ScrollBox/ScrollBox';
import styles from './Tabs.module.scss';
import { Tab } from './Tabs.type';

type Props = {
  tabsId: string;
  tabs: Tab[];
};

export const Tabs: FC<Props> = ({ tabsId, tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.id ?? '');
  const tab = tabs.find((item) => item.id === activeTab);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <div className={clsx(styles.root, 'tabs')}>
      <div role="tablist" className={clsx(styles.tablistWrapper, 'tabs-tablistWrapper')}>
        <LazyMotionWrapperFull>
          <ScrollBox className={clsx(styles.tablist, 'tabs-tablist')}>
            {tabs.map((item) => {
              const isActive = item.id === activeTab;
              return (
                <button
                  key={item.id}
                  role="tab"
                  className={clsx(styles.tab, 'tabs-tab', {
                    [styles.tabActive]: isActive,
                    'tabs-tab--active': isActive,
                  })}
                  id={`tab-${item.id}`}
                  data-testid={`tab-${item.id}`}
                  aria-controls={`panel-${item.id}`}
                  aria-selected={isActive ? 'true' : 'false'}
                  onClick={() => {
                    setActiveTab(item.id);
                    item.onClick?.();
                  }}
                >
                  {!!item.icon && <span className={styles.tabIcon}>{item.icon}</span>}
                  <span className="c-fs-body2 tabs-tab-label">{item.title}</span>
                  <AnimatePresence>
                    {isActive && (
                      <m.div
                        layout
                        className={clsx(styles.tabUnderline, 'tabs-tab-underline')}
                        layoutId={`${tabsId}-underline`}
                        transition={{ duration: 0.3 }}
                      />
                    )}
                  </AnimatePresence>
                </button>
              );
            })}
          </ScrollBox>
        </LazyMotionWrapperFull>
        <div className={clsx(styles.tablistOverflow, 'tabs-tablistOverflow')}></div>
      </div>
      {!!tab && !!tab.content && (
        <div role="tabpanel" id={`panel-${tab.id}`} aria-labelledby={`tab-${tab.id}`} className="tabs-content">
          {tab.content}
        </div>
      )}
    </div>
  );
};
