import { FiltersPayloadRow } from '../types/CarFilters.types';
import { ProductListDataPayload } from '../types/CataloguePage.types';
import { carmaCsrBizClient } from './instance/carmaBizClient';

export const getPLPCarData = async (data: {
  pageSize?: number;
  page?: number;
  sort?: string;
  text?: string;
  filters?: FiltersPayloadRow[];
  fuzzy?: boolean;
}) => {
  const plpCarData: ProductListDataPayload = await carmaCsrBizClient
    .post('vehicles', {
      headers: {
        'Content-Type': 'application/plp+json',
      },
      json: data,
    })
    .json();
  return plpCarData;
};
